/**
 * Main wrapper
 */
.select-search__input {
    background: #ffff;	
    border: 1px solid #ced4da !important;	
    color: #495057;	
    padding-left:10px !important;	
    min-height: 38px !important;
}

.select-search__input:focus {	
    border-color: #80bdff !important;	
    outline: 0;	
}   

.select-search__input:focus-within {	
    border-color: #80bdff !important;	
    outline: 0;	
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !important;	
}

.select-search__input::placeholder {	
    color: black;	
    opacity: 1; /* Firefox */	
}	
  	
.select-search__input:-ms-input-placeholder { /* Internet Explorer 10-11 */	
   color: black;	
}	
  	
.select-search__input::-ms-input-placeholder { /* Microsoft Edge */	
   color: black;	
}

.select-search {
    position: relative;
    font-family: "Nunito Sans", sans-serif;
    box-sizing: border-box;
}
  
.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}
  
  /**
  * Value wrapper
  */
.select-search__value {
    position: relative;
    z-index: 1;
}
  
.select-search__value::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: calc(50% - 4px);
    right: 19px;
    width: 8px;
    height: 8px;
}

.has-focus .select-search__value::after {
    transform: rotate(225deg) !important;
}
  
  /**
  * Input
  */
.select-search__input {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 40px 0 16px;
    border: 1px solid transparent;
    border-radius: 3px;
    outline: none;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    -webkit-appearance: none;
}
  
.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
}
  
.select-search__input:not([readonly]):focus {
    cursor: initial;
}
  
  /**
  * Options wrapper
  */
.select-search__select {
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
    z-index: 5556 !important;
    background: #fff;
}
  
  /**
  * Options
  */
.select-search__options {
    list-style: none;
    padding: 0;
    z-index: 5556 !important;
    margin: 0 !important;
}
  
  /**
  * Option row
  */
.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}
  
  /**
  * Option
  */
.select-search__option,
.select-search__not-found {
    display: block;
    width: 100%;
    padding: 5px 15px;
    border: none;
    outline: none;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-weight: 200 !important;
    color: #495057;
    text-transform: none !important;
    letter-spacing: 0px !important;
    text-align: left;
    cursor: pointer;
    background: #fff;
}
  
.select-search--multiple .select-search__option {
    height: 48px;
}
  
.select-search__option.is-selected {
    background: rgb(5, 147, 255);
    color: #fff;
}
  
.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: rgba(47, 204, 139, 0.1);
}
  
.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: rgb(5, 147, 255);
    color: #fff;
}
  
  /**
  * Group
  */
.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    padding: 8px 16px;
    background: #fff;
}
  
  /**
  * States
  */
.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}
  
.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}
  
  /**
  * Modifiers
  */
.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}
  
  .select-search:not(.is-loading):not(.select-search--multiple)
    .select-search__value::after {
    transform: rotate(45deg);
    border-right: 2px solid #00000091;
    border-bottom: 2px solid #00000091;
    pointer-events: none;
  }
  
.select-search--multiple .select-search__input {
    cursor: initial;
}
  
.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}
  
.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}
  
.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: rgb(5, 147, 255);
}
  
.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    left: 0px;
    top: calc(100% + 7px);
    border: 1px rgb(206, 212, 218);
    width: 100%;
    padding: 0px;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    max-height: 250px;
    overflow-x: hidden;
    z-index: 5556;
    color: rgb(0, 0, 0) !important;
}
  
.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 250px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}
  
.select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
}
  