.social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 15px 5px;
  margin: 0px 10px;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  border: 1px solid rgba(0,0,0,.1);
}

.social-icon img {
  height: 1.5rem;
  width: 1.5rem;
}

.social-icon:hover {
  background: #e5f4ff;
}

.custom-row-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
}
