.registration-form {
  margin: 0px auto;
}

.registration-form form {
  padding: 0px 50px 0px 50px;
}

.registration-form h2 {
  margin: 0 0 15px;
}

.registration-form h4 {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #002244;
  opacity: 1;
}

.registration-form .hint-text {
  letter-spacing: 0px;
  color: #373b3c;
  text-align: center;
  font-size: 14px; 
}

.registration-form .login-link {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #0593ff;
}

.registration-form .form-label {
  text-align: left;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0px;
  color: #373b3c;
  display: inline-block !important;
}
.registration-form .required::after {
  content: " *" !important;
  color: red !important;
  font-weight: 100 !important;
}

.form-control,
.btn {
  min-height: 38px;
  border-radius: 2px;
}
.btn:after {
  content: "";
}

.registration-btn {
  font-size: 15px;
  font-weight: bold;
}

.or-seperator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 15px 0px;
}

.or-seperator::before,
.or-seperator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #7a8a92;
  color: #7a8a92;
}

.or-seperator:not(:empty)::before {
  margin-right: 0.25em;
}

.or-seperator:not(:empty)::after {
  margin-left: 0.25em;
}

.social-media-option-container {
  margin: 15px;
  font-weight: 600;
  font-size: 14px;
}

.social-btn {
  margin: 10px 0;
  font-size: 14px !important;
  text-align: center;
  line-height: 24px;
  font-weight: 600 !important;
}

/*.social-btn:hover {
  background-color: #026276 !important;
}*/

.social-btn-i {
  float: left;
  margin: 4px 15px 0 5px;
  min-width: 15px;
}
.input-group-addon .fa {
  font-size: 18px;
}

.registration-form .terms {
  max-width: 420px;
  color: #525a5c;
  font-size: 12px;
}

.registration-form .formbox {
  display: flex;
  font-size: 12px;
  color: #525a5c;
  margin: 15px 0px 0px 0px;
}

.registration-form .checkbox-style {
  height: 20px;
  width: 18px;
  margin-right: 10px;
  vertical-align: middle;
}

.registration-form .g-recaptcha {
  transform:scale(0.77);
  -webkit-transform:scal(0.77);
  transform-origin:0 0;
  -webkit-transform-origin:0 0;
}

.social-login-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 90%;
}

.social-media-options {
  width: var(--spacing-64);
  height: var(--spacing-64);
  padding-top: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.registration-form select {
  background-color: white;
  border: 1px solid #b9cdd5;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 1em 0.5em 0.5em;
  box-sizing: border-box;
  width: 100%;
}

.error-space {
  color: red;
  text-align: left;
  font-size: 12px;
}

.hidden {
  display: none;
}

.form-container-custom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.registration-form .form-group {
  margin-bottom: 1rem;
  width: 48%;
}

[class^="bi-"]::before,
[class*=" bi-"]::before {
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: text-bottom;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #11a7d9 !important;
  margin-left: 3px !important;
  margin-top: 11px !important;
}
/* Customm Checkbox */

/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 27px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid rgb(145, 141, 141);
  background-color: #ffff;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 5px;
  top: 0px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.passwordPopup{
  transition: all 4s linear;
}

@media screen and (max-width: 480px) {
.registration-form .form-group { 
   width: 100% !important;
 }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #495057;
  opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #495057;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
  color: #495057;
  }