/* REgistration Form CSS */
.registration-form {
  margin: 0px auto;
}

.registration-form h2 {
  margin: 0 0 15px;
}

.registration-form h4 {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #002244;
  opacity: 1;
}

.registration-form .hint-text {
  letter-spacing: 0px;
  color: #373b3c;
  text-align: center;
  font-size: 14px;
}

.registration-form .login-link {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #0593ff;
}

.registration-form .form-label {
  text-align: left;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0px;
  color: #373b3c;
  display: inline-block !important;
}
.registration-form .required::after {
  content: " *" !important;
  color: red !important;
  font-weight: 100 !important;
}

.btn:after {
  content: "";
}

.registration-btn {
  font-size: 15px;
  font-weight: bold;
}

.registration-form .terms {
  color: #525a5c;
  font-size: 12px;
  text-align: justify;
  margin-bottom: 2em;
}

.registration-form .formbox {
  display: flex;
  font-size: 12px;
  color: #525a5c;
  margin: 15px 0px 0px 0px;
}

.registration-form .checkbox-style {
  height: 20px;
  width: 18px;
  margin-right: 10px;
  vertical-align: middle;
}

.registration-form .g-recaptcha {
  transform: scale(0.77);
  -webkit-transform: scal(0.77);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}

.social-login-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 90%;
}

.social-media-options {
  width: var(--spacing-64);
  height: var(--spacing-64);
  padding-top: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.registration-form select {
  background-color: white;
  border: 1px solid #b9cdd5;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 1em 0.5em 0.5em;
  box-sizing: border-box;
  width: 100%;
}

.form-container-custom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.registration-form .form-group {
  margin-bottom: 1rem;
  width: 48%;
}

[class^="bi-"]::before,
[class*=" bi-"]::before {
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: text-bottom;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #11a7d9 !important;
  margin-left: 3px !important;
  margin-top: 11px !important;
}
/* Customm Checkbox */

/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 27px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid rgb(145, 141, 141);
  background-color: #ffff;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 5px;
  top: 0px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.passwordPopup {
  transition: all 4s linear;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #495057;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #495057;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #495057;
}

/* Success Pop up container stylings */
.success_popup_container {
  display: flex;
  flex-direction: column;
  padding: 15px 35px 15px 35px;
  text-align: center;
  width: 35vw;
}

.success_popup_hint {
  padding: 15px 35px 15px 35px;
}

.registration-form form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0px;
  width: 35vw;
  padding: 35px 50px 0px 50px;
}

.hidden {
  display: none;
}

.error-space {
  padding-bottom: 10px;
  color: red;
  text-align: left;
  font-size: 12px;
}

.error-space-center {
  padding-top: 10px;
  color: red;
  text-align: center;
  font-size: 12px;
}

.chipSelected {
  background-color: #c4dff585 !important;
  color: rgb(0, 0, 0) !important;
}

/* Customm Checkbox */

/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 27px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid rgb(145, 141, 141);
  background-color: #ffff;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 5px;
  top: 0px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* The container */
.radio-container {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio-checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: rgb(255, 255, 255);
  border: 0.2px solid #737272;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .radio-checkmark {
  background-color: rgb(255, 255, 255);
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .radio-checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .radio-checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .radio-checkmark:after {
  top: 3px;
  left: 3px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: white;
}

/*Media Queries*/

@media screen and (max-width: 1350px) {
  .registration-form form {
    width: 65vw;
  }
}

@media screen and (max-width: 900px) {
  .registration-form form {
    width: 75vw;
  }
}

@media screen and (max-width: 700px) {
  .registration-form form {
    width: 100vw;
  }
}

@media screen and (max-width: 480px) {
  .registration-form .form-group {
    width: 100% !important;
  }
}

/* This media query is used for: iPhone 13 mini, iPhone 12 mini, iPhone 11 Pro, iPhone Xs, and iPhone X*/
/* 2340x1080 pixels at 476ppi */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .registration-form form {
    width: 100vw;
  }
}

/* This media query is used for: iPhone 13, iPhone 12 and iPhone 12 Pro */
/* 2532x1170 pixels at 460ppi */
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .registration-form form {
    width: 100vw;
  }
}

/* This media query is used for: iPhone 13 Pro Max and iPhone 12 Pro Max */
/* 2778x1284 pixels at 458ppi */
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .registration-form form {
    width: 100vw;
  }
}

/* This media query is used for: iPhone 11 and iPhone XR */
/* 1792x828px at 326ppi */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .registration-form form {
    width: 100vw;
  }
}

/* This media query is used for: iPhone 11 Pro Max and iPhone Xs Max */
/* 2688x1242px at 458ppi */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .registration-form form {
    width: 100vw;
  }
}
