.forgetpassword {
  margin: 0px auto;
  font-size: 13px;
}
.forgetpassword-form {
  padding: 0px 50px 0px 50px;
}
.forgetpassword h2 {
  margin: 0 0 15px;
}

.login-form .hint-text {
  color: #777;
  padding-bottom: 15px;
  text-align: center;
  font-size: 13px;
}
.form-control,
.btn {
  min-height: 38px;
  border-radius: 2px;
}
.login-btn {
  font-size: 15px;
  font-weight: bold;
}

.social-btn {
  margin: 10px 0;
  font-size: 14px !important;
  text-align: center;
  line-height: 24px;
  font-weight: 600 !important;
}

/*.social-btn:hover {
  background-color: #026276 !important;
}*/

.social-btn-i {
  float: left;
  margin: 4px 15px 0 5px;
  min-width: 15px;
}
.input-group-addon .fa {
  font-size: 18px;
}

.error-space {
  padding-bottom: 10px;
  color: red;
  text-align: left;
  font-size: 12px;
}

.hidden {
  display: none;
}

.back-link {
  text-decoration: underline;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0px;
  color: #0593ff;
}

.form-label {
  text-align: left;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0px;
  color: #373b3c;
  display: inline-block !important;
}
