.login-form {
  margin: 0px auto;
}

.login-form form {
  padding: 0px 50px 0px 50px;
}

.login-form h2 {
  margin: 0 0 15px;
}

.login-form h4 {
  font-weight: 600;
  font-size: 18px;
}

.login-form .hint-text {
  color: #373b3c;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
}

.login-form .register-link {
  text-decoration: underline;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0px;
  color: #0593ff;
}

.login-form .g-recaptcha {
  transform:scale(0.77);
  -webkit-transform:scal(0.77);
  transform-origin:0 0;
  -webkit-transform-origin:0 0;
}

.login-form .forget-password-link {
  text-decoration: underline;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #0593ff;
  float: right;
}

.form-control,
.btn {
  min-height: 38px;
  border-radius: 2px;
}
.login-btn {
  font-size: 15px;
  font-weight: bold;
}

.or-seperator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 10px 0 0;
}

.or-seperator::before,
.or-seperator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #000;
}

.or-seperator:not(:empty)::before {
  margin-right: 0.75em;
}

.or-seperator:not(:empty)::after {
  margin-left: 0.75em;
}

.social-btn {
  margin: 10px 0;
  font-size: 14px !important;
  text-align: center;
  line-height: 24px;
  font-weight: 600 !important;
}

/*.social-btn:hover {
  background-color: #026276 !important;
}*/

.social-btn-i {
  float: left;
  margin: 4px 15px 0 5px;
  min-width: 15px;
}

.social-media-option-container {
  margin: 17px 0px 0px 0px;
  font-weight: 600;
  font-size: 14px;
}

.input-group-addon .fa {
  font-size: 18px;
}
.error-space {
  padding-bottom: 10px;
  color: red;
  text-align: left;
  font-size: 12px;
}

.error-space-center {
  padding-top: 10px;
  color: red;
  text-align: center;
  font-size: 12px;
}

.hidden {
  display: none;
}

.login-form .form-label {
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #373b3c;
  margin-bottom: .7rem;
  display: inline-block !important;
}

.center-handler {
  display: flex;
  align-items: center;
  flex-flow: column;
}

.small-text {
  font-size: 12px;
  color: #495057;
}

.red {
  color: red;
}
