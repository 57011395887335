/* Popup style */
.modal-box {
  display: flex;
  position: fixed;
  top: 0;
  background-color: rgb(0 0 0 / 50%);
  width: 100%;
  height: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  z-index: 99999999;
}

h1 {
  line-height: inherit !important;
}

.box {
  max-height: 85vh;
  overflow: auto;
  background: #fff;
  box-shadow: 0px 0px 20px #00000029;
  border-radius: 8px;
}

.close-icon-container {
  display: flex;
  justify-content: flex-end;
  margin: 5px 5px 0px 0px;
}

.close-icon {
  cursor: pointer;
  background: #eff0f5;
  color: #a6bcc6;
  width: 30px;
  height: 29px;
  line-height: 21px;
  text-align: center;
  border: 0.5px solid #eff0f5;
  font-size: 24px;
  border-radius: 100%;
}

/*.close-icon:hover {
  background: #026276;
  color: #ffffff;
  border: 1px solid #026276;
}*/

.powered-by {
  display: flex;
  justify-content: flex-end;
  margin: 15px 20px 20px 0px;
  font-size: 12px;
}

.btn:after {
  content: "";
}


/* Modal Scrollbar Stylings*/
/*::-webkit-scrollbar {
  width: 8px;
}


::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 8px rgb(0 116 141 / 70%);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgb(0 116 141);
  -webkit-box-shadow: inset 0 0 8px rgb(18 118 140 / 70%);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgb(0 116 141);
}*/

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.row {
    display: contents !important;
}